<template>
    <div class="content_container customer_terms customer_finance_protect">
        <div class="content">
            <div class="banner">
                <h1>금융소비자보호</h1>
            </div>
			<div class="m_terms_menu_wrapper">
				<ul class="terms_menu">
					<li class="current">
						<a @click="toggleMenu">금융소비자보호</a>
					</li>
					<li>
						<router-link to="/customer/nego" @click="toggleMenu">금리인하요구권</router-link>
					</li>
					<li>
						<router-link to="/customer/withdraw" @click="toggleMenu">청약철회권</router-link>
					</li>
					<li>
						<router-link to="/customer/dataopen" @click="toggleMenu">자료열람청구권</router-link>
					</li>
					<li>
						<router-link to="/customer/termination" @click="toggleMenu">위법계약해지권</router-link>
					</li>
				</ul>
			</div>
            <section class="terms">
				<ul class="terms_menu">
					<li class="current">
						<router-link to="/customer/financeprotect">금융소비자보호</router-link>
					</li>
					<li>
						<router-link to="/customer/nego">금리인하요구권</router-link>
					</li>
					<li>
						<router-link to="/customer/withdraw">청약철회권</router-link>
					</li>
					<li>
						<router-link to="/customer/dataopen">자료열람청구권</router-link>
					</li>
					<li>
						<router-link to="/customer/termination">위법계약해지권</router-link>
					</li>
				</ul>
				<div>
					<div class="text_box">
						<div class="csfont2 title" style="color:#000;">금융소비자보호</div>

						<br><br>

						<section class="customer_p_bow">
							<h2>금융소비자 보호헌장</h2>
							<p class="sub_text">무궁화캐피탈의 모든 임직원은 금융소비자보호가 핵심가치임을 인식하고 금융소비자와 함께 성장 하고 발전하는 금융회사가 되기 위해 다짐합니다.</p>
							<ol>
								<li>
									<p class="bow_num">1</p>
									<p><span>금융소비자</span>의 다양한 요구를 경영활동에 적극 반영하여 금융소비자 권익 증진에 힘쓰겠습니다.</p>
								</li>
								<li>
									<p class="bow_num">2</p>
									<p><span>금융소비자</span>보호를 위해 업무프로세스를 개선하고 이를 적극 실천하겠습니다.</p>
								</li>
								<li>
									<p class="bow_num">3</p>
									<p><span>금융소비자</span>에게 불합리한 차별없이 공정하고 평등한 금융서비스를 제공하겠습니다.</p>
								</li>
								<li>
									<p class="bow_num">4</p>
									<p><span>금융소비자</span>의 불만을 신속하고 적극적인 해결과 예방을 위해 최선의 노력을 기울이겠습니다.</p>
								</li>
							</ol>
						</section>
						<section class="customer_p_info">
							<h2>금융소비자 보호체계</h2>
							<p class="sub_text">금융소비자 보호총괄책임자 및 주관부서를 두어 금융소비자보호가 이루어질 수 있도록 최선을 다하겠습니다.</p>
							<div class="department_callnum">
								<h3>금융소비자보호 총괄책임자 및 주관부서</h3>
								<div>
									<p>준법감시인</p>
									<p>02-2047-7102</p>
								</div>
								<div>
									<p>주관부서</p>
									<p>02-2047-7100</p>
								</div>
							</div>
							<div class="civil_complaint_guide">
								<h3>민원 처리 안내</h3>
								<p>금융소비자의 민원업무와 관련해서 회사의 업무처리에 대한 불만사항이나 개선할 점이 있으면 다음과 같은 방법으로 접수하실수 있습니다.</p>
							</div>
							<div class="complaint_methods">
								<h3>민원 접수방법</h3>
								<ul>
									<li><p>민원처리 부서를 통한 유선 접수 (02-2047-7100)</p></li>
									<li><p>전자민원을 통한 접수<a href="/customer/onlinecontact" target="_blank" class="link_fss">바로가기</a></p></li>
								</ul>
							</div>
							<div class="complaint_period">
								<h3>민원 처리 기간</h3>
								<ul>
									<li><p>접수된 민원은 시안에 따라 최대 14영업일 이내 처리 하겠습니다.</p></li>
									<li><p>다만, 사실관계 확인에 시일이 소요될 경우 그 기간은 연장될수 있습니다.</p></li>
								</ul>
							</div>
						</section>
						<section class="customer_protect_site">
							<h2>금융정보 한곳에</h2>
							<div>
								<p><a href="https://fine.fss.or.kr/main/index.jsp" target="_blank"><img src="@/assets/images/sub/customer_protect/site01.jpg" alt="파인 사이트 바로가기" /></a></p>
								<p><a href="https://www.fss.or.kr/fss/kr/main.html" target="_blank"><img src="@/assets/images/sub/customer_protect/site02.jpg" alt="금융감독원 사이트 바로가기" /></a></p>
								<p><a href="https://www.crefia.or.kr" target="_blank"><img src="@/assets/images/sub/customer_protect/site05.jpg" alt="여신금융협회 사이트 바로가기" /></a></p>
								<p><a href="https://www.fcsc.kr/" target="_blank"><img src="@/assets/images/sub/customer_protect/site03.jpg" alt="e금융민원센터 사이트 바로가기" /></a></p>
								<p><a href="https://consumer.fss.or.kr/fss/consumer/main.jsp" target="_blank"><img src="@/assets/images/sub/customer_protect/site04.jpg" alt="금융소비자보호처" /></a></p>
							</div>
						</section>
						<!-- 반응형 적용시 정렬을 위한 더미 태그 START -->
						<p style="color:#fff; height:1px; overflow:hidden">------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</p>
						<!-- 반응형 적용시 정렬을 위한 더미 태그 END -->
					</div>
				</div>
            </section>
        </div>
    </div>
</template>

<script>
export default {
    name : 'FinanceProtect',
	methods:{
		toggleMenu(){
			document.querySelector('.m_terms_menu_wrapper .terms_menu').classList.toggle('on');
		},
	}
}
</script>

